/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import $ from 'jquery';
window.$ = $;
import ReactOnRails from 'react-on-rails';

import Rails from 'rails-ujs';
import Popper from 'popper.js';
import 'bootstrap';

import 'htmx.org';

import Chartkick from 'chartkick';
window.Chartkick = Chartkick;

import Chart from 'chart.js';
Chartkick.addAdapter(Chart);

Rails.start();

import './stylesheets';
import '../src/vendor/message-bus';
import ZbonCalendar from '../src/zbon/calendar';
import ImageContainer from '../src/obs_images/obs_images';
import '../src/vendor/add.js';
import '../src/vendor/orders.js';
import '../src/vendor/sticky-table-headers';
import DiscountListContainer from '../src/discounts/DiscountListContainer';
import DiscountEditorContainer from '../src/discounts/DiscountEditorContainer';
import CsvUpload from '../src/csv_upload/csv_upload';

import RoomEditor from '../src/room_editor/RoomEditor';
import '../src/opening_times/OpeningTimes';
import '../src/material/material';
import '../src/analysis/analysis';
import '../src/export';
import ImportCustomers from '../src/import_customers/import_customers';
import CouponForm from '../src/coupons/CouponForm';

ReactOnRails.register({
  ImageContainer,
  ZbonCalendar,
  DiscountListContainer,
  RoomEditor,
  DiscountEditorContainer,
  CsvUpload,
  ImportCustomers,
  CouponForm,
});

$(document).ready(function () {
  $('table').stickyTableHeaders();
  if ($.fn.summernote) {
    $('.rich-text').summernote();
  }

  MessageBus.start();

  MessageBus.subscribe(
    '/messages',
    (message) => {
      if (message.text) {
        alert(message.text);
      }
    },
    window.lastMessageID,
  );
});
